import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ContextProvider } from '../../services/context-provider/context.provider';
import { ContextService } from '../../../ui-shared-components.services';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly contextService: ContextService,
    private contextProvider: ContextProvider,
    protected readonly authenticationService: AuthenticationService
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const checkToken = await this.authenticationService.checkRefreshToken(); //also need to check if token is expired

    // Force the user to log in if currently unauthenticated.
    if (!this.isAuthenticated() || checkToken === null) {
      //There is a bug in keycloak where the last URL is being replace (not assigned) causing back button from keycloak to go to the page prior to the one expected to be rendered:
      //https://github.com/mauriciovigolo/keycloak-angular/issues/209
      //keycloak-js library is using replace instead of assign causing the browser back button to go to page previous to the last page prior to keycloak redirect
      //putting this in here as a fix to ensure the back click from keycloak goes to the correct page.
      window.history.pushState(window.history.state, window.document.title, window.location.href);

      //const forceIdp = 'keycloak-oidc'; //use this to bypass keycloak login and go directly to IDP login
      const forceIdp = route?.data?.forceIdp;
      await this.keycloakService.login({
        redirectUri: window.location.origin + state.url,
        idpHint: forceIdp,
      });

      this.contextProvider.setCurrentUser(null);
      return false;
    }

    if (this.isAuthenticated()) {
      const user = await this.contextService.getContextUserProfile();
      this.contextProvider.setCurrentUser(user);
    } else {
      this.contextProvider.setCurrentUser(null);
    }

    return true;
  }

  private isAuthenticated() {
    if (this.authenticated === null) {
      return false;
    }
    return this.authenticated;
  }
}
