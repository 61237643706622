// import { UploadLookup } from './upload-lookup.interface';
import { DocumentsUpload } from './documents-upload.interface';

//displayValue: is the title on the document uploader (ex: Copy of 'medical letter').
//  -Also shows in the documentType column in the PDF if no doc types selected (only 1 doc type).
//displayCategory: is the category column value in the PDF (only used in PDF). Friendly text to the documentCategory alias (this is needed for optOut)
//  -If this isn't specified, it will use the property alias transformed (notWorkingReasonPersonal) using startCase(camelCase('notWorkingReasonPersonal').
//optOutLabel: is the text for the opt out checkbox in the document loader.

export const FbDocumentCategories: { [name: string]: DocumentsUpload } = {
  applicant: {
    identification: {
      displayValue: 'Identification',
      categoryTitleOverride: 'Upload proof of identity',
      documentTypes: ['Identification document'],
      hideDocumentTypeLabel: true,
      // documentTypes: [
      //   "Driver's license",
      //   'Non-driver government issued identification card',
      //   'Passport',
      //   'Birth, marriage or baptismal certificate',
      //   'Employee or student card',
      //   'Band registry number, status card, or Metis card',
      //   'Alberta Personal Health Card',
      //   'Social Insurance Number',
      // ],
      order: 1,
      optOutLabel:
        'I do not have any form of identification and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
  deceased: {
    statementOfDeath: {
      order: 1,
      displayValue: 'Statement of death',
      documentTypes: ['Statement of death'],
      categoryTitleOverride: 'Statement of death/death certificate',
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have statement of death/death certificate and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    statusCanada: {
      displayValue: 'Status in Canada',
      categoryTitleOverride: 'Proof of status in Canada',
      order: 3,
      documentTypes: ['Status document'],
      hideDocumentTypeLabel: true,
      // documentTypes: [
      //   'Birth certificate',
      //   'Certificate of Indian Status card',
      //   'Permanent residency card',
      //   'Canadian passport',
      //   'Other',
      // ],
      optOutLabel:
        'I do not have any proof of status in Canada and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    identityAddress: {
      displayCategory: 'Identity Residency',
      displayValue: 'Identity and residency',
      categoryTitleOverride: 'Proof of identity and Alberta residency ',
      order: 2,
      documentTypes: ['Identity/Residency document'],
      hideDocumentTypeLabel: true,
      // documentTypes: [
      //   "Driver's license",
      //   'Passport',
      //   'Birth, marriage, baptismal certificates',
      //   'Personal health card',
      //   'Social insurance card',
      //   'Employee, student ID cards',
      //   'Bank or credit card',
      //   'Certificate of Indian Status card',
      //   'Firearms Acquisition Certificate',
      //   'School, health, hospital records',
      //   'Parole card',
      //   'Vehicle registration/insurance',
      //   'Non-driver ID card',
      //   'Utility bill',
      //   'Other',
      // ],
      optOutLabel:
        'I do not have any form of identification or Alberta residency and I understand that not providing these documents with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
  spousePartner: {
    identification: {
      displayValue: 'Identification',
      categoryTitleOverride: 'Upload proof of identity',
      documentTypes: ['Identification document'],
      hideDocumentTypeLabel: true,
      // documentTypes: [
      //   "Driver's license",
      //   'Non-driver government issued identification card',
      //   'Passport',
      //   'Birth, marriage or baptismal certificate',
      //   'Employee or student card',
      //   'Band registry number, status card, or Metis card',
      //   'Alberta Personal Health Card',
      //   'Social Insurance Number',
      // ],
      order: 1,
      optOutLabel:
        'My <<spouseLabel>> does not have any form of identification and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },

  incomes: {
    bankAccounts: {
      displayValue: 'Bank statement(s) showing 30 days of transactions from the date of passing',
      documentTypes: ['Bank statement'],
      needSupportDocument: true,
      order: 1,
      // helpText: 'Provide bank statements that show 30 days transactions from the date of passing.',
      categoryTitleOverride: 'Provide all bank statements for at least 30 days prior to the date of death',
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have access to the bank accounts/statements for <<replacementLabel>> and am unable to obtain them, and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Bank statement(s) are required.',
    },
    other: {
      displayValue: 'Other documents',
      categoryTitleOverride: 'Other documents <span class="document-optional">(Optional)</span>',
      documentTypes: ['Other documents'],
      needSupportDocument: true,
      order: 3,
      isOptional: true,
      suppressOptionalLabel: true,
      hideDocumentTypeLabel: true,
      helpText: 'Example: paystubs, land title, vehicle purchase statement etc.',
    },
  },
  additionalResources: {
    lifeInsurance: {
      order: 1,
      displayValue: 'Life insurance policy value',
      categoryTitleOverride: 'Proof of life insurance policy value',
      documentTypes: ['Policy document'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have any proof of life insurance value and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    prepaidFuneral: {
      order: 2,
      displayValue: 'Prepaid funeral',
      categoryTitleOverride: 'Proof of prepaid funeral',
      documentTypes: ['Funeral invoice'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have any proof of prepaid funeral and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    groupProgram: {
      order: 3,
      displayValue: 'Denial from Group/Programs',
      categoryTitleOverride: 'Proof of denial from groups/programs',
      documentTypes: ['Proof of denial'],
      // documentTypes: [
      //   'Denial from Client of Public Trustee',
      //   'Denial from Last Post Fund',
      //   'Denial from Child and Youth Services',
      // ],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have any proof of denial and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    causeOfDeath: {
      order: 4,
      displayValue: 'Denial from other programs',
      categoryTitleOverride: 'Proof of denial from other programs',
      documentTypes: ['Proof of denial'],
      // documentTypes: [
      //   'Denial from Workplace compensation board',
      //   'Denial from Motor vehicle insurance',
      //   'Denial from Victims of crime program',
      // ],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have any proof of denial and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    deathBenefitForm: {
      order: 5,
      displayValue: 'CPP death benefit form',
      categoryTitleOverride: 'CPP death benefit form',
      documentTypes: ['CPP death benefit form'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have the completed Request for Estimate - Canada Pension Plan Death Benefit form and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
  benefits: {
    fspInvoice: {
      order: 1,
      displayValue: 'Funeral service provider invoices/receipts',
      categoryTitleOverride: 'Funeral service provider invoices/receipts',
      documentTypes: ['Funeral service provider invoices/receipts'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have proof of invoices/receipts and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    cemeteryInvoice: {
      order: 2,
      displayValue: 'Cemetery invoices/receipts',
      categoryTitleOverride: 'Cemetery invoices/receipts',
      documentTypes: ['Cemetery invoices/receipts'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have proof of invoices/receipts and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
  paymentDirection: {
    invoices: {
      order: 1,
      displayValue: 'Proof of payment',
      categoryTitleOverride: 'Funeral service provider and cemetery invoices/quotes/receipts',
      documentTypes: ['Supporting documents'],
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      optOutLabel:
        'I do not have invoices/quotes/receipts and I understand that not providing these with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
    exceptionalAmounts: {
      order: 2,
      displayValue: 'Exceptional Amounts',
      documentTypes: ['Exceptional amounts'],
      categoryTitleOverride: 'Proof of exceptional amounts <span class="document-optional">(Optional)</span>',
      needSupportDocument: true,
      hideDocumentTypeLabel: true,
      isOptional: true,
      suppressOptionalLabel: true,
      helpText:
        'These may include documents detailing the health and/or safety reason resulting in additional costs, an additional invoice/quote/receipt that breaks down the specific costs, and/or an explanation of the extraordinary circumstances.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
  review: {
    consentDocument: {
      displayValue: 'Consent and Declaration',
      documentTypes: ['Consent document'],
      categoryTitleOverride: 'Consent and Declaration',
      hideDocumentTypeLabel: true,
      order: 1,
      optOutLabel:
        'I am unable to provide the Consent and Declaration form and I understand that not providing this document with my online application will cause delays and may result in my application being denied.',
      requiredErrorOverride: 'Missing. Upload here or check the box above.',
    },
  },
};
