import { IntakeLabelCollection } from '../../entities/intake-label.interface';
export const AishMedicalLabels: IntakeLabelCollection = {
  isDoctorSubmittingReport: {
    label:
      'Is your doctor and/or specialist submitting your <a href="https://aish.alberta.ca/assets/documents/AISH-MedicalReport_061022.pdf" target="_blank" >AISH medical report</a> directly to AISH?',

    alternateLabel: 'Is your doctor and/or specialist submitting your AISH medical report directly to AISH?',
  },
  hasAdditionalDocuments: {
    label: 'Are there additional medical documents that you want to include with your application?',
  },
};
